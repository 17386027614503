import React, { useState, useEffect } from "react";


function Resume() {
  const education = [
    {
      institution: "Regis University",
      degree: "B.S in Computer Science",
      graduationDate: "Aug 2018",
    }
  ];

  const experience = [
    {
      company: "Zeek",
      position: "Software Engineer",
      startDate: "2022",
      endDate: "",
      responsibilities: [

      ],
    },
    {
      company: "Virus Geeks",
      position: "Software Engineer",
      startDate: "2021",
      endDate: "2021",
      responsibilities: [

      ],
    },
  ];

  const personalexperience = [
    {
      company: "Browser Extension - React App +  Marketing Site - React App ",
      position: "none",
      startDate: "Summer 2022",
      endDate: "Oct 2022",
      responsibilities: [
        "Developed browser extension + marketing website using (React, Next.js, Tailwind)"
      ],
      vid: <iframe width="600" height="250" src="https://www.youtube.com/embed/hMThNzXw234?si=zU8j43wTeKawTVe2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
      vid2:<a class="mt-2 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" href="https://dovie.org" target="_blank">Click to view live website</a>,

    },
    {
      company: "Coin Guide - Full Stack App",
      position: "none",
      startDate: "Summer 2022",
      endDate: "Oct 2022",
      responsibilities: [
        "Developed full stack app using (React, Next.js, Sqlite, Tailwind, Strapi)"
      ],
      vid: <iframe width="600" height="250" src="https://www.youtube.com/embed/yaJaZVUzDF0?si=p0jwMLIFqRuSlcey" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
      vid2:<a class="mt-2 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" href="https://cointutorial.org" target="_blank">Click to view live website</a>,

    },
    {
      company: "Educational SaaS - Full Stack App",
      position: "none",
      startDate: "Summer 2022",
      endDate: "Oct 2022",
      responsibilities: [
        "Developed full stack app using (React, Next.js, Prisma, Postgres, Tailwind, Stripe)"
      ],
      vid: <iframe width="600" height="250" src="https://www.youtube.com/embed/z04nWiSBC4A?si=hrODJK_vQ5TbGyDL" title="cdl saas" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
      vid2:<a class="mt-2 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" href="https://cdlstudy.org" target="_blank">Click to view live website</a>,

    },
    {
      company: "Educational iOS Studying Apps",
      position: "none",
      startDate: "Summer 2022",
      endDate: "Oct 2022",
      responsibilities: [
        "Developed iOS educational mobile apps using React Native"
      ],
      vid: <iframe width="600" height="250" src="https://www.youtube.com/embed/idRMbE7d_E4" title="draunt software" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
      vid2:<a class="mt-2 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" href="https://apps.apple.com/us/developer/draunt-llc/id1067629917" target="_blank">Click to view live apps</a>,

    },
    {
      company: "Business Name Generator – Full Stack App",
      position: "none",
      startDate: "Summer 2021",
      endDate: "Jul 2021",
      responsibilities: [
        'Developed full stack web app using (Fastify, React, Node, Rest API)'
      ],
      vid: <iframe width="600" height="250" src="https://www.youtube.com/embed/OOkRnv4k3OE" title="business name generator" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
    },
    {
      company: "Stock Portfolio Manager – Full Stack MERN App",
      position: "none",
      startDate: "Spring 2021",
      endDate: "May 2021",
      responsibilities: [
        'Developed full stack web app using MERN stack (MySQL, Express, React, Node)'
      ],
      vid: <iframe width="600" height="250" src="https://www.youtube.com/embed/bQk_CSkaDec" title="stock portfolio manager" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
    },
    {
      company: "Meal Delivery Ranker – React App ",
      position: "none",
      startDate: "Winter 2020",
      endDate: "May 2021",
      responsibilities: [
        'Developed web app front-end using React, Bulma, JavaScript, and Node.js'
      ],
      vid: <iframe width="600" height="250" src="https://www.youtube.com/embed/HUAczaW6028" title="meal delivery ranker" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
      vid2:<a class="mt-2 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" href="https://mealdelivery-one.vercel.app/" target="_blank">Click to view live website</a>,
    },
  ];

  const skills = [
    "JavaScript",
    "React",
    "React Native",
    "Node.js",
    "HTML/CSS",
    "MySQL",
    "Git",
  ];

  const [isVerified, setIsVerified] = useState(false);
  const [hovered, setHovered] = useState(false);

  const checkLogin = () => {
    setIsVerified(true);
  };

  const mergedStyles = (isHovered) =>
    Object.assign({}, styles.loginBtn, isHovered ? styles.loginBtnHover : {});

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#FFFFFF",
    },
    loginBtn: {
      backgroundColor: "#1E90FF",
      color: "#FFFFFF",
      fontSize: "32px",
      padding: "16px 64px",
      borderRadius: "8px",
      cursor: "pointer",
      display: "block",
      margin: "0 auto",
      transition: "all 0.2s ease-in-out",
      boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
      border: "none",
      outline: "none",
      fontFamily: "Helvetica Neue, Arial, sans-serif",
      fontWeight: "bold",
      textTransform: "uppercase",
      letterSpacing: "1px",
      textDecoration: "none",
    },
    loginBtnHover: {
      transform: "scale(1.05)",
      boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.2)",
    },
  };

  const Resume = () => {
    return (
      <div className="bg-gray-100 py-10">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h1 className="text-3xl leading-6 font-bold text-gray-900">
              Johnny Vuong
            </h1>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Software Engineer | JohnnyVanVuong@gmail.com | 206-380-6500
            </p>
          </div>
       
        </div>

        <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-6">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-2xl font-bold text-gray-900">Project Experience</h2>
      </div>
      <div className="border-t border-gray-200">
        <ul className="grid grid-cols-2 gap-2">
          {personalexperience.map((exp) => (
            <li key={exp.company} className="py-4 px-4 sm:px-6">
              <div className="flex space-x-3">
                <div className="min-w-0 flex-1">
                  <div className="text-lg font-medium text-gray-900">
                    {exp.company}
                  </div>
                  <div className="mt-1 text-sm text-gray-500">
                  {exp.position !== "none" && <>{exp.position},</> } 
                  </div>
                  <ul className="mt-3 text-sm text-gray-500">
                    {exp.responsibilities.map((resp) => (
                      <li key={resp} className="mb-1">
                        <span className="text-gray-900">&#8226;</span>{" "}
                        {resp}
                      </li>
                    ))}
                  </ul>
                  {exp.vid}
                  {exp.vid2}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>

<div className="grid grid-cols-2 gap-2">



    <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-6">
  <div className="px-4 py-5 sm:px-6">
    <h2 className="text-2xl font-bold text-gray-900">Education</h2>
  </div>
  <div className="border-t border-gray-200">
    <ul>
      {education.map((edu) => (
        <li key={edu.institution}>
          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              {edu.degree}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {edu.institution}, {edu.graduationDate}
            </dd>
          </div>
        </li>
      ))}
    </ul>
  </div>
</div>

<div className="bg-white shadow overflow-hidden sm:rounded-lg mt-6">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-2xl font-bold text-gray-900">Skills</h2>
      </div>
      <div className="border-t border-gray-200">
        <div className="px-4 py-5 sm:px-6">
          <ul className="grid grid-cols-2 gap-4">
            {skills.map((skill) => (
              <li
                key={skill}
                className="bg-gray-100 py-2 px-3 text-sm font-medium text-gray-700 rounded-md"
              >
                {skill}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
</div>



  </div>
</div>
    );
  };
  useEffect(() => {
    document.title = 'Johnny.Software | Portfoliio';
    checkLogin();
  }, []);

  return (

<div>
{isVerified ? (
  <Resume />
) : (
  <div style={styles.container}>
    <button
      style={mergedStyles(hovered)}
      onClick={checkLogin}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      Click to View Resume
    </button>
  </div>
)}
</div>
);


}

export default Resume;